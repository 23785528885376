@use "@angular/material" as mat;
@use '../src/assets/scss/variables.scss';

@font-face {
  font-family: 'Futura PT Book Italic W08 Rg';
  src: url('/assets/fonts/1557753/6faf5364-ea50-4785-940f-0154592603de.woff2')
  format('woff2'),
  url('/assets/fonts/1557753/dc7f82ec-da94-4617-8b3a-c37955233ba8.woff')
  format('woff'),
  url('/assets/fonts/1557753/efb958bb-1fae-4606-9291-881ac03cb7a4.eot?#iefix')
  format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT W08 Book';
  src: url('/assets/fonts/1557755/246beac4-a6e0-48ba-b519-8e4654a3b418.woff2')
  format('woff2'),
  url('/assets/fonts/1557755/6fc655d8-e68f-41a4-a600-7c4aa3c24736.woff')
  format('woff'),
  url('/assets/fonts/1557755/405bf683-b88d-438c-993c-687eb042db5f.eot?#iefix')
  format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT W08 Medium';
  src: url('/assets/fonts/1557757/4623ec33-91af-4660-9482-151c452f630a.woff2')
  format('woff2'),
  url('/assets/fonts/1557757/454d14aa-61ab-4567-8fe4-4138c13f68ea.woff')
  format('woff'),
  url('/assets/fonts/1557757/53c559de-09f7-42be-96c5-4c6d69fb6ae0.eot?#iefix')
  format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT W08 Bold';
  src: url('/assets/fonts/1557765/fd8fd029-4667-43ea-8a91-585619719db6.eot?#iefix');
  src: url('/assets/fonts/1557765/fd8fd029-4667-43ea-8a91-585619719db6.eot?#iefix')
  format('eot'),
  url('/assets/fonts/1557765/19bfa4ed-39d5-4e20-9a97-4031bcc29228.woff2')
  format('woff2'),
  url('/assets/fonts/1557765/bc078cce-ad85-4b52-bf29-7fc88b1364b6.woff')
  format('woff'),
  url('/assets/fonts/1557765/19f74998-f7e8-41e5-90ad-0d0e57f90ce4.ttf')
  format('truetype');
  font-display: swap;
}

$my-primary: mat.define-palette(variables.$palette, 500);
$my-accent: mat.$deep-purple-palette;

$custom-typography : mat.define-typography-config(
  $headline: mat.define-typography-level(4rem, 4rem, 400, "Futura PT W08 Medium"),
  $title: mat.define-typography-level(1.5rem, 1.5rem, 400),
);
@include mat.core($custom-typography);

$my-theme: mat.define-light-theme((color: (primary: $my-primary, accent:$my-accent), typography: $custom-typography));

@include mat.all-component-themes($my-theme);
