$mainColor:    #007bff;
$unselectedColor: #AAA;
$errorColor: #a94442;
$successColor: #3c763d;

$fsproPink: #FF3F80;
$fsproRHRed: #f95c5c;
$grayishWhite: #E1E1E6;

$primaryColor:#3F51B5;
$light-primary-text: white;
$dark-primary-text: rgba(black, 0.87);
$backgroundColor1:#6477DF;

$palette: (
  50: lighten($primaryColor, 25),
  100: lighten($primaryColor, 20),
  200: lighten($primaryColor, 15),
  300: lighten($primaryColor, 10),
  400: lighten($primaryColor,5),
  500: $primaryColor,
  600: darken($primaryColor, 10),
  700: darken($primaryColor, 15),
  800: darken($primaryColor, 20),
  900: darken($primaryColor, 25),
  A100: purple,
  A200: $backgroundColor1,//used in Fab button background-color
  //#ffab40,
  A400: green,
  //#ff9100,
  A700: #ff6d00,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
