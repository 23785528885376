@import './theme';
@import '../src/assets/scss/variables.scss';

.preview-container{
    border-bottom: solid 2px #fff;
    position:absolute;
    background-repeat: no-repeat;
    display:none;
}

.shaka-bottom-controls{
    display: flex;
    flex-direction: column-reverse;
}

.preview-time{
  position: absolute;
  color: #fff;
  font-size: 12px;
  margin: auto;
  bottom: 5px;
  left: calc(50% - 25px);
}

.shaka-spinner-path {
  stroke: #F95C5C !important;
}

.shaka-spinner-svg {
  height: 50px !important;
  width: 50px !important;
}

.shaka-spinner {
  padding: 0 !important;
}

.shaka-text-container {
  span {
    font-size: 40px;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #00837B;
  height: 4px;
}

.mat-tab-group.mat-primary.blue .mat-ink-bar {
  background-color: #0057B7;
}

.mat-tab-group.mat-primary.red .mat-ink-bar {
  background-color: #F01D26;
}

.mat-tab-group.mat-primary.orange .mat-ink-bar {
  background-color: #FF6C22;
}

.mat-tab-group.mat-primary.violet .mat-ink-bar {
  background-color: #872C84;
}

.mat-tab-header {
  border-top: 1px dotted #9a9a9a;
  border-bottom: none;
}

.mat-tab-group.mat-tab-multiline {
  max-width: 1060px;
  margin: 18px auto 0 auto;
  &.single {
    .mat-tab-label-container {
      display: none;
    }
  }
  .mat-tab-header:first-child {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke-linecap="square" viewBox="0 0 21 42" width="21"> <line fill="none" stroke="black" stroke-width="1" x1="0" y1="41" x2="21" y2="41"/></svg>');
    background-position: 0 -1px;
    .mat-tab-header-pagination {
      display: none;
    }
    .mat-tab-labels {
      flex-wrap: wrap;
      justify-content: space-around;
      .mat-tab-label {
        min-width: unset;
      }
    }
  }
  &.align-left {
    .mat-tab-header:first-child {
      .mat-tab-labels {
        justify-content: flex-start;
        .mat-tab-label {
          margin: 0 2%;
          justify-content: flex-start;
        }
      }
    }
  }
}

.mat-tab-label {
  opacity: 1 !important;
  color: #000000;
  padding: 0 !important;
  height: 42px !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

@media screen and (max-width: 599px) {

  .shaka-text-container {
    span {
      font-size: 20px;
    }
  }

  .mat-tab-header {
    display: none;
  }
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }




.mat-dialog-container {
    border-radius: 0 !important;
}

.mat-dialog-container {
  border-radius: 0 !important;
  width: 80%;
  max-width: 480px;
}
.large .mat-dialog-container {
  width: 100%;
}
.info-snackbar {
  border-radius: 0 !important;
  background-color: #262626;
  font-family: "Futura PT W08 Medium", "Trebuchet MS", Arial, sans-serif;
}
.info-snackbar .mat-simple-snackbar-action {
  color: #32d8dc !important;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #222222 !important;
}
.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #00837B;
  height: 4px;
}
.mat-tab-group.mat-primary.blue .mat-ink-bar {
  background-color: #0057B7;
}
.mat-tab-group.mat-primary.red .mat-ink-bar {
  background-color: #F01D26;
}
.mat-tab-group.mat-primary.orange .mat-ink-bar {
  background-color: #FF6C22;
}
.mat-tab-group.mat-primary.violet .mat-ink-bar {
  background-color: #872C84;
}
.mat-tab-group.single .mat-tab-label-container {
  display: none;
}
.mat-tab-header {
  border-top: 1px dotted #9a9a9a;
  border-bottom: none;
}
.mat-tab-group.mat-tab-multiline {
  max-width: 1060px;
  margin: 18px auto 0 auto;
}
.mat-tab-group.mat-tab-multiline .mat-tab-header:first-child {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke-linecap="square" viewBox="0 0 21 42" width="21"> <line fill="none" stroke="black" stroke-width="1" x1="0" y1="41" x2="21" y2="41"/></svg>');
  background-position: 0 -1px;
}
.mat-tab-group.mat-tab-multiline .mat-tab-header:first-child .mat-tab-header-pagination {
  display: none;
}
.mat-tab-group.mat-tab-multiline .mat-tab-header:first-child .mat-tab-labels {
  flex-wrap: wrap;
  justify-content: space-around;
}
.mat-tab-group.mat-tab-multiline.align-left .mat-tab-header:first-child .mat-tab-labels {
  justify-content: flex-start;
}
.mat-tab-group.mat-tab-multiline .mat-tab-header:first-child .mat-tab-labels .mat-tab-label {
  min-width: unset;
}
.mat-tab-group.mat-tab-multiline.align-left .mat-tab-header:first-child .mat-tab-labels .mat-tab-label {
  margin: 0 2%;
  justify-content: flex-start;
}
.mat-tab-group {
  max-width: 1060px !important;
  margin: auto;
}
.mat-tab-label {
  opacity: 1 !important;
  color: #000000;
  padding: 0 !important;
  height: 42px !important;
}
.mat-expansion-panel-body {
  padding: 0 !important;
}
@media screen and (max-width: 599px) {
  .mat-tab-header {
    display: none !important;
  }
}
.mat-calendar-body-selected {
  background-color: #fb5e59;
}
.mat-calendar-body-label {
  visibility: hidden;
}
.mat-calendar-body-label:after {
  content: '';
}
.invitation .mat-tab-group.mat-tab-multiline .mat-tab-header:first-child .mat-tab-labels .mat-tab-label {
  margin: 0 20px;
}

#bookingfilm {

  .mat-horizontal-stepper-header-container {
    font-family: "Futura PT W08 Medium", "Futura PT W08 Medium", "Futura PT Medium", Futura, "Trebuchet MS", Arial, sans-serif;
    font-size: 18px;
    border-bottom: 1px solid $grayishWhite;
    display: flex;
    justify-content: space-between;
  }

  .mat-stepper-horizontal-line {
    display: none;
  }

  .mat-horizontal-stepper-header {
    height: 46px;
  }

  .mat-step-header .mat-step-icon {
    background-color: $grayishWhite;
  }

  .mat-step-header .mat-step-label, .mat-step-header .mat-step-optional {
    font-size: 18px;
    color: #8D8D99;
  }

  .mat-step-header .mat-step-label.mat-step-label-active {
    color: #262625;
  }

  .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: $fsproPink;
  }

  .mat-stepper-next {
    background-color: $fsproPink;
  }

  .mat-placeholder-required {
    color: $fsproPink;
  }

  .mat-step-header {
    @media (max-width: 1080px) {
      padding-left: 0;
    }
  }

  .mat-vertical-content-container {
    @media (max-width: 1080px) {
      margin-left: 12px;
    }
  }
}

.img-fit-contain {
  object-fit: contain;
}
